import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Nexus Blog is now re-loading itself. Please be patient.
        </p>
        <p>Subscribe and share our social media platform.</p>
      </header>
    </div>
  );
}

export default App;
